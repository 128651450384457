import logo from "./logo.webp"

const clientData = {
    client_entity: 7,
    attorney_firm: 'Fair Share Lawyers, PLLC',
    attorney_name: 'Robert Johnson',
    attorney_number: '1-800-719-2721',
    attorney_email: 'robert@fairsharelawyers.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_fair_share_lawyers+(720p).mp4',
    mainColor: '#1D729D',
    secondaryColor: '#4fba74',
    siteLink: 'http://fairsharelawyers.com',
    logo
}

export default clientData